@font-face {
    font-family: 'Altmann Grotesk';
    src: url('AltmannGrotesk-Thin.eot');
    src: local('Altmann Grotesk Thin'), local('AltmannGrotesk-Thin'),
        url('AltmannGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
        url('AltmannGrotesk-Thin.woff2') format('woff2'),
        url('AltmannGrotesk-Thin.woff') format('woff'),
        url('AltmannGrotesk-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Altmann Grotesk';
    src: url('AltmannGrotesk-Bold.eot');
    src: local('Altmann Grotesk Bold'), local('AltmannGrotesk-Bold'),
        url('AltmannGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('AltmannGrotesk-Bold.woff2') format('woff2'),
        url('AltmannGrotesk-Bold.woff') format('woff'),
        url('AltmannGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Altmann Grotesk';
    src: url('AltmannGrotesk-Medium.eot');
    src: local('Altmann Grotesk Medium'), local('AltmannGrotesk-Medium'),
        url('AltmannGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('AltmannGrotesk-Medium.woff2') format('woff2'),
        url('AltmannGrotesk-Medium.woff') format('woff'),
        url('AltmannGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Altmann Grotesk';
    src: url('AltmannGrotesk-Light.eot');
    src: local('Altmann Grotesk Light'), local('AltmannGrotesk-Light'),
        url('AltmannGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('AltmannGrotesk-Light.woff2') format('woff2'),
        url('AltmannGrotesk-Light.woff') format('woff'),
        url('AltmannGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Altmann Grotesk';
    src: url('AltmannGrotesk-Regular.eot');
    src: local('Altmann Grotesk'), local('AltmannGrotesk-Regular'),
        url('AltmannGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('AltmannGrotesk-Regular.woff2') format('woff2'),
        url('AltmannGrotesk-Regular.woff') format('woff'),
        url('AltmannGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

