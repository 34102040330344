:root {
  /* Variaveis de cores */
  --primary-dark: #FCCD12;
  --primary: #F3D856;
  --primary-light: #FDF9E7;
  --light: #fff;
  --gray: #BFBFBF;
  --dark: #383838;
  --danger: #E74C3C;
  --warning: #F1C40F;
  --success: #07BC0C;

  /* Variaveis tamanho fontes */
  --tam-h1: 5.4375rem;
  --tam-h2: 3.3125rem;
  --tam-h3: 2.625rem;
  --tam-h4: 1.875rem;
  --tam-h5: 1.3125rem;
  --tam-h6: 1.125rem;
  --tam-p: 1rem;
  --tam-small: .875rem;
  
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--light);
}

html {
  scroll-behavior: smooth !important;
  font-size: 14px;
}

h1,h2,h3,h4,h5,h6,p,span,label,b, li, div,a,button, td, th {
  font-family: 'Altmann Grotesk' !important;
  margin: 0;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h1);
  line-height: 120%;
  color: var(--dark);
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h2);
  line-height: 120%;
  color: var(--dark);
}
h3 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h3);
  line-height: 120%;
  color: var(--dark);
}
h4 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h4);
  line-height: 110%;
  color: var(--dark);
}
h5 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h5);
  line-height: 110%;
  color: var(--dark);
}
h6, th {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-h6);
  line-height: 110%;
  color: var(--dark);
}
p, a, label, td {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-p);
  line-height: 150%;
  color: var(--dark);
}
small {
  font-style: normal;
  font-weight: 400;
  font-size: var(--tam-small);
  line-height: 150%;
  color: var(--dark);
}

a {
  color: inherit;
  text-decoration: none;
}

button.button-primary {
  background-color: var(--primary-dark);
  border-color: var(--dark);
  color: var(--dark);
}

button.button-primary:hover, button.button-primary:focus, button.button-primary:active {
  background-color: var(--primary) !important;
  border-color: var(--dark) !important;
  color: var(--dark) !important;
}

.empty-data {
  width: 100%;
  margin-top: 1.875rem;
  text-align: center;
  opacity: 0.7;
  font-weight: 500;
}

.hide-left {
  left: -6rem !important;
}
.hide-right {
  right: -12rem !important;
}
.hide-top {
  top: -6.25rem !important;
}


/* Custom Scroll */
.custom-scroll::-webkit-scrollbar {
  width: .625rem;
}

/* Track */
.custom-scrool::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--gray);
  border-left: 1px solid var(--gray);
}

/* Handle */
.custom-scrool::-webkit-scrollbar-thumb {
  background: var(--gray); 
  border-radius: 3px;
  border-bottom-right-radius: 0;
}

@media (max-width: 576px) {
  .custom-scroll::-webkit-scrollbar {
    width: .375rem;
  }
  .hide-top {
    top: -9.25rem !important;
  }
}

/* Aside Menu Mobile */
.aside-menu-container {
  width:250px !important;

  .offcanvas-body {
    padding: 0;
    width: 250px;
  }
}

@media (min-width: 992px) {
  .aside-menu-container {
    display: none;
  }
}

/* Modal */

.modal {
  padding: 0 1rem;
}

.modal .modal-lg {
  max-width: 62rem;
}

/* Autocomplete */

.rbt-menu .dropdown-item.active {
  color: var(--dark);
  background-color: var(--primary-dark)
}

/* swal */

.swal2-actions .swal2-confirm {
  background-color: var(--primary);
  color: var(--dark);
  transition: all 0.5s;
  outline: none;
  box-shadow: none;
}

.swal2-actions .swal2-confirm::hover {
  background-color: var(--primary-dark);
  outline: none;
  box-shadow: none !important;
  color: var(--dark);
}

.swal2-actions .swal2-confirm:focus {
  background-color: var(--primary-dark);
  outline: none;
  box-shadow: none !important;
  color: var(--dark);
}

.hover-list:hover {
  background-color: #f7f7f7;
}

/* Imagens */
.img-object-fit-contain {
  object-fit: contain !important;
}
